<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
          v-bind="props"
          append-icon="mdi-menu-down"
          class="text-none"
          flat
          rounded="lg"
          text="How to"
      />
    </template>
    <v-list>
      <v-list-item
          @click="youtubeOverlayUnity?.show()"
          prepend-icon="mdi-video"
      >
        <v-list-item-title>Upload a Unity Game</v-list-item-title>
      </v-list-item>

      <v-list-item
          v-if="gating.showGodot"
          href="/articles/share-godot-web-builds-online"
          target="_blank"
          prepend-icon="mdi-file-document"
      >
        <v-list-item-title>Upload a Godot Godot Game</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

  <YoutubeOverlay ref="youtubeOverlayUnity" video-url="https://www.youtube.com/embed/ZyeB3_rYM1E" style="z-index:200000" />
  <YoutubeOverlay ref="youtubeOverlayGodot" video-url="https://www.youtube.com/embed/ZyeB3_rYM1E" style="z-index:200000" />
</template>

<script setup>
import YoutubeOverlay from "~/components/generic/YoutubeOverlay.vue";
import useGating from "~/composables/useGating.js";

const gating = useGating();
const youtubeOverlayUnity = ref(null);
const youtubeOverlayGodot = ref(null);
</script>